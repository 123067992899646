// Typography
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700&display=swap');

// Partials
@import 'styles/variables';
@import 'styles/forms';
@import 'styles/modals';
@import 'styles/cards';

// Custom Theming for Angular Material
@import 'styles/material/dra-theme';

@font-face {
  font-family: 'Bogle';
  src: url('assets/fonts/Bogle-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Bogle';
  src: url('assets/fonts/Bogle-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Bogle';
  src: url('assets/fonts/Bogle-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

/**********************************************************************
                            Global Styles
***********************************************************************/
* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  color: $black;
  font-family: $font-stack;
  font-size: 14px;
  margin: 0;
}

h1 {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: normal;
}

a {
  color: $link-color;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba($black, 0.25);
  margin: 36px 0;
  padding: 0;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba($black, 0.25);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba($black, 0.25);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba($black, 0.25);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgba($black, 0.25);
}

@media print {
  .do-not-print {
    display: none !important;
  }
  * {
    overflow: visible !important;
  }
}

/**********************************************************************
  Search Bar - TODO: Remove, is not working at component level for some reason
***********************************************************************/
.search {
  background: rgba(0, 0, 0, 0);
  border: none;
  font-family: $font-stack;
  font-size: 14px;
  padding: 10px 12px;
  width: 350px;
}

.action-btn {
  background: none;
  border: none;
  border-radius: 8px;
  height: 30px;
  width: 30px;

  &:hover {
    background: rgba($black, 0.05);
  }

  img {
    align-self: center;
    display: flex;
    width: 100%;
  }
}

.chip {
  background: rgba($black, 0.05);
  border-radius: 8px;
  color: rgba($black, 0.5);
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 8px;
  margin-left: 0;
  padding: 4px 6px;
}

.mat-chip.mat-standard-chip::after {
  background: transparent;
}

.ui-autocomplete-panel {
  position: relative;
  top: 0;
  background: $white;
}

/* NOTE: This overwrite is used because a custom class for
Material Tooltips must be provided as an input. The styles
cannot be overwritten in the same way as other Material components. */
.dra-mat-tooltip {
  background: $black;
  border-radius: 12px;
  color: $white !important;
  font-size: 12px;
  margin-bottom: 8px !important;
  padding: 4px 6px;
}

.dra-table {
  .cdk-header-row {
    text-align: left;
    background-color: #f8f9fb;
    height: 36px;
  }

  .cdk-row {
    border-bottom: 1px solid rgba($black, 0.05);
    margin-left: 16px;
    text-align: left;

    &:last-child {
      border-bottom: none;
    }
  }

  .cdk-header-cell {
    vertical-align: middle;
    color: rgba($black, 0.7);
    font-size: 12px;
    font-weight: 600;
    padding-right: 32px;

    &:first-child {
      padding-left: 12px;
      padding-right: 12px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .cdk-cell {
    vertical-align: middle;
    padding-right: 32px;

    &:first-child {
      padding-left: 12px;
      padding-right: 12px;
    }

    &:last-child {
      padding: 0;
    }
  }

  tr {
    height: 48px;
    vertical-align: middle;
  }
}

.dra-sort::after {
  background-image: url('assets/icons/sort.svg');
  content: '';
  height: 16px;
  width: 16px;
  position: absolute;
  // top: 8px;
  top: calc(50% - 8px);
}

.dra-sorted-asc::after {
  background-image: url('assets/icons/sort-desc.svg');
  transform: rotate(180deg);
}

.dra-sorted-desc::after {
  background-image: url('assets/icons/sort-desc.svg');
}

.mat-dialog-container {
  max-width: 1200px !important;
  border-radius: 16px !important;
}

.text-right {
  text-align: right;
}

.dra-label {
  color: $black-color;
  font-family: $font-stack;
  font-size: 12px;
  line-height: 1.33;

  &.dra-label-disabled {
    color: $black-color-light;
  }
}

.dra-snack-bar {
  max-height: 500px;
  overflow-y: auto;
}

.dra-snack-bar button {
  color: #00aed6;
}

.dra-white-spinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $white !important;
  }
}

.dra-blue-spinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $primary !important;
  }
}

.file-search-filter-select {
  margin-top: 30px !important;
  border-radius: 16px !important;
  padding: 8px 0;
  width: 100px !important;
}

.dra-company-select {
  border-radius: 16px !important;
  padding: 8px 0 !important;
}

.clearance-chip-list-container {
  .mat-chip-list-wrapper {
    max-height: 50vh;
    overflow-y: auto;
  }

  .mat-standard-chip {
    border-radius: 8px;
    min-height: 24px;
  }

  .duplicate-first {
    border: 2px solid $warning;
  }

  .duplicate-second {
    background-color: $warning;
  }

  .mat-chip {
    font-size: 12px;
    font-weight: 400;
  }
}

.clearance-inspector-tabs {
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 1px solid $black-color-lightest;
  }
}

.clearance-inspector-expansion-panel {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.02), 0px 4px 6px 1px rgba(0, 0, 0, 0.06);
  }
}

.mat-simple-snackbar-action {
  color: $alt-4;
}

.piece-inspector-confirmation-accordion-dark {
  mat-accordion {
    .mat-expansion-panel {
      box-shadow: none;
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.05));
    }

    .mat-expansion-panel:last-of-type {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .mat-expansion-panel:first-of-type {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    .mat-expansion-panel-header {
      padding-left: 12px;
      height: 56px;
    }

    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
      color: white;
    }

    .dark-expansion-panel {
      background: rgba($black, 0.87);
    }
  }
}

.piece-inspector-confirmation-accordion {
  mat-accordion {
    .mat-expansion-panel {
      box-shadow: none;
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.05));
      border: 1px solid $black-color-lightest;
    }

    .mat-expansion-panel:last-of-type {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .mat-expansion-panel:first-of-type {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    .mat-expansion-panel-header {
      padding-left: 12px;
      height: 56px;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

.piece-inspector-confirmation-dialog-mat-tab-group,
.client-dashboard-mat-tabs {
  .mat-tab-label-active {
    background-color: transparent !important;
    opacity: 1 !important;
  }
}

.client-dashboard-stepper {
  .mat-horizontal-stepper-header-container {
    padding: 0 56px;
    background-color: $white;
  }

  .mat-step-header {
    height: 65px;
  }

  .mat-step-icon {
    border-radius: 8px;
  }
}

.country-selection-chip-list {
  .mat-standard-chip {
    display: flex;
    justify-content: center;
    background-color: $primary-color-lightest;
    border: 1px solid 1px rgba(30, 31, 36, 0.12);
  }

  .selected {
    background-color: #3136ff;
    border: 1px solid #3136ff;
    color: $white;
  }

  // .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  //   background-color: #3136ff;
  //   border: 1px solid #3136ff;
  // }

  .mat-chip {
    font-size: 12px;
    font-weight: 400;
  }
}

// new container form field remove outline if auto chosen
.form-field-read-only {
  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: $white !important;
  }

  .mat-select {
    .mat-select-arrow {
      border-top: none;
    }
  }

  .mat-form-field-infix {
    padding: 0.5em 0 0 0;
  }
}

.dra-mat-select {
  .mat-form-field {
    width: 100%;

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }
  }
}

.dra-date-picker {
  .mat-form-field {
    width: 100%;

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }
  }
}

.dra-mat-form-field-new-container {
  .mat-form-field {
    width: 100%;
    height: 100%;
    margin-bottom: 8px;

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
      max-height: 815px;

      .mat-form-field-flex {
        height: 815px;
      }
    }
  }
}

.new-container-chip-list-container {
  .mat-chip-list-wrapper {
    max-height: 790px;
    overflow-y: auto;
  }

  .mat-standard-chip {
    border-radius: 8px;
    min-height: 24px;
  }

  .duplicate-first {
    border: 2px solid $warning;
  }

  .duplicate-second {
    background-color: $warning;
  }

  .mat-chip {
    font-size: 12px;
    font-weight: 400;
  }
}

.error-dialog {
  .mat-dialog-container {
    max-width: 420px !important;
  }
}

.associated-batch-detail-dialog {
  .mat-dialog-container {
    max-height: 90vh;
    overflow-y: auto;
    width: 1000px;
  }
}

.mat-progress-bar-container {
  width: 150px;
  display: flex;
  align-items: center;

  .mat-progress-bar {
    border-radius: 2px;
  }
  .blue {
    .mat-progress-bar-fill::after {
      background-color: #6479ff;
      border-radius: 2px;
    }
  }

  .green {
    .mat-progress-bar-fill::after {
      background-color: $success;
    }
  }
}

.slider-cell {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background: #7682be !important;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: $primary-color-lighter !important;
  }
}

.configuratr-select {
  .mat-form-field {
    margin: 0;
  }

  .mat-select-value-text {
    color: $primary;
    font-size: 16px;
    font-weight: 600;
  }
}

.configuratr-table-select {
  .mat-form-field {
    margin: 0;
    padding: 14px 16px 0 24px;
  }

  .mat-select-value-text {
    color: $primary;
    font-size: 20px;
    font-weight: 600;
  }

  .mat-select::placeholder {
    color: $primary;
    font-size: 20px;
    font-weight: 600;
  }

  .mat-form-field-hide-placeholder .mat-select-placeholder {
    color: $primary !important;
    font-size: 20px;
    font-weight: 600;
  }
}

.configuratr-checkbox {
  .mdc-checkbox__background {
    height: 34px;
    width: 34px;
    border-radius: 8px;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0;
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
    ~ .mdc-checkbox__background {
    border-color: #757bb1;
    background-color: #ebecfa;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
    ~ .mdc-checkbox__background {
    border-color: $primary;
    background-color: #ebecfa;
  }

  .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: $primary;
    border-color: $primary;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background {
    background-color: $primary;
    border-color: $primary;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: $primary;
    border-color: $primary;
  }

  .mdc-checkbox__ripple {
    width: 34px;
    height: 34px;
  }

  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    display: none;
  }
}
