/* Custom Sass Variables
to be included in component-level or app-level stylesheets.

If you are looking for a variable that isn't in this file,
note that some variables, e.g. Material color palette variables,
may be referenced from the Angular Material library in node_modules.
*/

/**********************************************************************
                            Color Palette
***********************************************************************/
$black: #1b1c25;
$dark: #191d25;
$white: #ffffff;

$primary: #001996;
// $primary: #2b3487;
$primary-dark: #18206d;

$secondary: #e8084d;

$success: #18db71;
$warning: #ffb300;
$error: #ec2125;

$alt-1: #1400a1;
$alt-2: #0fb64b;
$alt-3: #b200ff;
$alt-4: #00aed6;
$alt-5: #ff7900;
$alt-6: '#FE6BBB';
$alt-7: #826af9;
$alt-8: #ffe700;

$link-color: #462cfa;
$sidebar-background-color: #dfe2f2;

$black-color-12: rgba($black, 0.12);
$black-color-87: rgba($black, 0.87);

$tracking-background: #f5f6fa;

@function lightColor($color) {
  @return rgba($color, 0.5);
}

@function lighterColor($color) {
  @return rgba($color, 0.25);
}

@function lightestColor($color) {
  @return rgba($color, 0.05);
}

$primary-color: $primary;
$primary-color-light: lightColor($primary-color);
$primary-color-lighter: lighterColor($primary-color);
$primary-color-lightest: lightestColor($primary-color);

$secondary-color: $secondary;
$secondary-color-light: lightColor($secondary-color);
$secondary-color-lighter: lighterColor($secondary-color);
$secondary-color-lightest: lightestColor($secondary-color);

$success-color: $success;
$success-color-light: lightColor($success-color);
$success-color-lighter: lighterColor($success-color);
$success-color-lightest: lightestColor($success-color);

$warning-color: $warning;
$warning-color-light: lightColor($warning-color);
$warning-color-lighter: lighterColor($warning-color);
$warning-color-lightest: lightestColor($warning-color);

$error-color: $error;
$error-color-light: lightColor($error-color);
$error-color-lighter: lighterColor($error-color);
$error-color-lightest: lightestColor($error-color);

$black-color: $black;
$black-color-light: lightColor($black-color);
$black-color-lighter: lighterColor($black-color);
$black-color-lightest: lightestColor($black-color);

$white-color: $white;
$white-color-light: lightColor($white-color);
$white-color-lighter: lighterColor($white-color);
$white-color-lightest: lightestColor($white-color);

$piece-verification-green: rgba($success, 0.5);
$piece-verification-blue: rgba($alt-4, 0.5);
$piece-verification-yellow: rgba($alt-8, 0.5);
$piece-verification-orange: rgba($alt-5, 0.5);
$piece-verification-red: rgba($error, 0.5);

/**********************************************************************
                              Typography
***********************************************************************/
$font-stack: 'Work Sans', sans-serif;

/**********************************************************************
                      Component/Layout Features
***********************************************************************/
$navbar-width: 72px;
$navbar-z-index: 20;

$tracking-header-height: 64px;
$tracking-header-training: #00ae84;

// TODO: Find a better way to handle this. Style guide recommends
// using opacity to lighten colors.
$navbar-secondary-background: rgb(233, 234, 243);
$navbar-secondary-height: 64px;
$navbar-secondary-z-index: 4;

$header-background-color: #e9eaf3;

$menu-drawer-width: 387px;

$section-list-width: 326px;

$tablet-breakpoint: 840px;
$mobile-breakpoint: 600px;
