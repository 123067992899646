@import '@angular/material/theming';
@import 'src/styles/variables';

@mixin dra-mat-menu-theme($config) {
  .cdk-overlay-container .mat-menu-panel {
    max-width: none;
    border-radius: 16px;
    margin-top: 4px;
  }
}
