@import 'variables';

/******************************************************************************
                                  Modal
******************************************************************************/
$modal-padding: 32px;

/* Modal Background */
.modal-bg {
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Freeze bg scroll */
  background-color: $black; /* Fallback color */
  background-color: rgba($black, 0.5); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.1), 0 0 8px 2px rgba(0, 0, 0, 0.03);
  margin: 30px auto; /* 15% from the top and centered */
  padding: $modal-padding;
  border: 1px solid #888;
  max-height: 85vh;
  max-width: 472px;
  overflow: auto;
  width: 95%;

  &.modal-alert {
    max-width: 416px;

    p {
      color: rgba($black, 0.5);
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    h2 {
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      line-height: 28px;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 16px;
  }

  p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  /* Make forms scrollable, while keeping header fixed at top */
  form {
    overflow-y: auto;
    overflow-x: hidden;
    height: 70vh;
  }

  footer {
    align-items: center;
    box-shadow: inset 0 1px 0 0 rgb(244, 245, 249);
    display: flex;
    justify-content: center;
    padding-top: 32px;
    // This is to force the box-shadow to stretch to the edge of the modal
    margin-left: -$modal-padding;
    margin-right: -$modal-padding;
    padding-left: calc(#{$modal-padding} - 4px);
    padding-right: calc(#{$modal-padding} - 4px);

    &.no-shadow {
      box-shadow: none;
    }

    button {
      align-items: center;
      border-radius: 8px;
      border: none;
      display: flex;
      font-family: $font-stack;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 24px;
      margin: 4px;
      padding: 8px 16px;
      width: 50%;

      &.cancel {
        background: rgba($black, 0.05);
        color: $primary;
      }

      &.cta {
        background: $primary;
        color: $white;
      }

      &.warn {
        background: $error;
        color: $white;
      }

      &:disabled {
        opacity: 0.25;
      }
    }
  }
}

.remove-pieces-dialog {
  height: 216px;
  width: 416px;
}

.share-files-dialog {
  width: 614px;
  overflow-y: auto;
}

.piece-verification-dialog {
  width: 368px;

  .mat-dialog-container {
    padding: 0;
  }
}

.hide-batches-dialog {
  height: 236px;
  width: 368px;
}

.new-batch-dialog {
  width: 464px;
  border-radius: 16px;

  .mat-dialog-container {
    padding: 0;
  }
}

.barcode-scanner-dialog {
  .mat-dialog-container {
    box-shadow: none;
    padding: 0;
    width: 100%;
    background-color: #6b6b71;
  }
}

.barcode-scanner-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.42);
}

.clearance-closeout-dialog {
  width: 600px;
}

.closeout-dialog {
  width: 600px;
}

.closeout-confirmation-dialog {
  .mat-dialog-container {
    width: 861px;
    max-height: 90vh;
  }
}

.piece-verification-dialog {
  width: 90%;
  height: 90vh;
}

.configuratr-package-sorts-dialog {
  // .mat-mdc-dialog-container {
  //   padding: 30px;
  // }
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 30px;
    border-radius: 16px;
  }
}
